<template>
  <div>
    <b-col class="bulk-update-title">
      <h5>Bulk Update Scheduled Operation</h5>
      <a href="#" class="nav-link-aside" @click="openOtherAside()">Bulk Update {{ otherBulkAsideLabel }}</a>
    </b-col>
    <b-row no-gutters>
      <b-col sm="12" lg="12">
        <span class="required-legend float-right p-0">* Required</span>
      </b-col>
    </b-row>

    <b-row class="no-gutters">
      <b-col sm="12" lg="5">
        <b-form-group label="Scheduled Operations:" label-class="col-head" label-for="standardOperation">
          <b-input-group>
            <v-select
              id="standardOperation"
              v-model="standardOperation"
              placeholder="Select"
              label="name"
              :class="$v.standardOperation.$error && !$v.standardOperation.required ? 'is-invalid' : ''"
              :filter-by="standardOperationsFilter"
              :options="standardOperationsList"
              :clearable="false"
              select-on-tab
            />
            <span class="required-asterisk">*</span>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-col sm="12" lg="12">
        <b-form-group label="Excede Job:" label-class="col-head" label-for="excedeJob">
          <v-select
            id="excedeJob"
            v-model="excedeJob"
            placeholder="Select"
            label="excedeJobId"
            :filter-by="excedeJobDropdownSearchFilter"
            :options="excedeJobOptions"
            :clearable="true"
            select-on-tab
          >
            <template #option="job">
              <span class="text-uppercase">{{ job.excedeJobId }} - {{ job.description }}</span>
            </template>
            <template #selected-option="selectedJob">
              <span class="text-uppercase">{{ selectedJob.excedeJobId }} - {{ selectedJob.description }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-button variant="primary mt-2" @click="addStandardOperation">
        <div>Add</div>
      </b-button>
    </b-row>
    <div v-if="showTable">
      <b-table
        striped
        no-border-collapse
        sticky-header="90vh"
        :fields="fields"
        :items="[stdOpToAdd]"
        class="align-middle mt-4 border"
      >
        <template #cell(duration)>
          <label class="w90px">Due:</label>
          <b-input
            v-model.number="$v.stdOpToAdd.durationDue.$model"
            class="w120px mr-2"
            :state="$v.$dirty && $v.stdOpToAdd.durationDue.$error ? false : null"
            type="number"
            @keypress="NumberFieldHelper.isNumber"
          />
          <div class="mt-2">
            <label class="w90px">Overdue:</label>
            <b-input
              v-model.number="$v.stdOpToAdd.durationOverdue.$model"
              class="w120px mr-2"
              :state="$v.$dirty && $v.stdOpToAdd.durationOverdue.$error ? false : null"
              type="number"
              @keypress="NumberFieldHelper.isNumber"
            />
          </div>
          <div
            v-if="$v.$dirty && ($v.stdOpToAdd.durationDue.$error || $v.stdOpToAdd.durationOverdue.$error)"
            class="mt-2 error"
          >
            {{ getErrorMessage($v.stdOpToAdd.durationDue, $v.stdOpToAdd.durationOverdue) }}
          </div>
        </template>
        <template #cell(mileage)>
          <label class="w90px">Due:</label>
          <b-input
            v-model.number="stdOpToAdd.mileageDue"
            class="w120px mr-2"
            :state="$v.$dirty && $v.stdOpToAdd.mileageDue.$error ? false : null"
            type="number"
            @keypress="NumberFieldHelper.isNumber"
          />
          <div class="mt-2">
            <label class="w90px">Overdue:</label>
            <b-input
              v-model.number="stdOpToAdd.mileageOverdue"
              class="w120px mr-2"
              :state="$v.$dirty && $v.stdOpToAdd.mileageOverdue.$error ? false : null"
              type="number"
              @keypress="NumberFieldHelper.isNumber"
            />
          </div>
          <div
            v-if="$v.$dirty && ($v.stdOpToAdd.mileageDue.$error || $v.stdOpToAdd.mileageOverdue.$error)"
            class="mt-2 error"
          >
            {{ getErrorMessage($v.stdOpToAdd.mileageDue, $v.stdOpToAdd.mileageOverdue) }}
          </div>
        </template>
        <template #cell(engineHours)>
          <label class="w90px">Due:</label>
          <b-input
            v-model.number="stdOpToAdd.engineHoursDue"
            class="w120px mr-2"
            :state="$v.$dirty && $v.stdOpToAdd.engineHoursDue.$error ? false : null"
            type="number"
            @keypress="NumberFieldHelper.isNumber"
          />
          <div class="mt-2">
            <label class="w90px">Overdue:</label>
            <b-input
              v-model.number="stdOpToAdd.engineHoursOverdue"
              class="w120px mr-2"
              :state="$v.$dirty && $v.stdOpToAdd.engineHoursOverdue.$error ? false : null"
              type="number"
              @keypress="NumberFieldHelper.isNumber"
            />
          </div>
          <div
            v-if="$v.$dirty && ($v.stdOpToAdd.engineHoursDue.$error || $v.stdOpToAdd.engineHoursOverdue.$error)"
            class="mt-2 error"
          >
            {{ getErrorMessage($v.stdOpToAdd.engineHoursDue, $v.stdOpToAdd.engineHoursOverdue) }}
          </div>
        </template>
      </b-table>

      <div v-if="numOfUnitsMissingLocation > 0">
        <h3>Locations Unassigned</h3>
        <p>
          {{ numOfUnitsMissingLocation }} of {{ totalUnits }} units selected have no location assigned. These units will
          not appear in the Operation Forecast until they have assigned locations. Click the Bulk Update Location link
          above to assign.
        </p>
      </div>

      <b-button variant="primary mt-2" @click="confirmSave">
        <div v-show="savingStandardOperation">
          <b-spinner small></b-spinner>
          Saving...
        </div>
        <div v-show="!savingStandardOperation">Save</div>
      </b-button>
      <b-button variant="secondary mt-2 ml-2" @click="cancel">
        <div>Cancel</div>
      </b-button>
    </div>
    <warning-modal
      id="BulkUpdateWarning"
      ref="BulkUpdateWarning"
      title="Bulk Updates"
      :warning-text="confirmationMessage"
      continue-btn-text="Yes"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
import Cloner from '@/shared/helpers/cloner';
import BulkUpdateService from '@/shared/services/BulkUpdateService';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import { integer, minValue, requiredIf, required } from 'vuelidate/lib/validators';
import { lessThanOrEqual, getStandardOperationErrorMessage } from '@/shared/helpers/validator-helper.js';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import DataHelper from '@/shared/helpers/data-helper';

// Components
import vSelect from 'vue-select';
import WarningModal from '@/shared/components/WarningModal';

// Vuex
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters } from 'vuex';

export default {
  name: 'BulkUpdateStandardOperations',
  components: {
    'v-select': vSelect,
    'warning-modal': WarningModal
  },
  props: {
    selectedUnits: {
      type: Array,
      required: true
    },
    otherBulkAsideLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      NumberFieldHelper: NumberFieldHelper,
      savingStandardOperation: false,
      showTable: false,
      stdOpToAdd: null,
      excedeJob: null,
      fields: [
        { key: 'duration', label: 'Duration', tdClass: 'align-middle' },
        { key: 'mileage', label: 'Mileage', tdClass: 'align-middle' },
        { key: 'engineHours', label: 'Engine Hours', tdClass: 'align-middle' }
      ]
    };
  },
  validations: {
    standardOperation: {
      required
    },
    stdOpToAdd: {
      durationDue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('durationOverdue'),
        lessThanOrEqual: lessThanOrEqual('durationOverdue')
      },
      durationOverdue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('durationDue')
      },
      mileageDue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('mileageOverdue'),
        lessThanOrEqual: lessThanOrEqual('mileageOverdue')
      },
      mileageOverdue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('mileageDue')
      },
      engineHoursDue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('engineHoursOverdue'),
        lessThanOrEqual: lessThanOrEqual('engineHoursOverdue')
      },
      engineHoursOverdue: {
        integer,
        minValue: minValue(0),
        required: requiredIf('engineHoursDue')
      }
    }
  },
  computed: {
    ...mapGetters({
      standardOperationsList: LookupGetters.GET_STANDARD_OPERATIONS_LIST
    }),
    excedeJobOptions: function () {
      return this.stdOpToAdd?.excedeJobOptions || [];
    },
    selectedUnitIds() {
      return this.selectedUnits.map(u => u.unitId);
    },
    numOfUnitsMissingLocation() {
      return this.selectedUnits.filter(u => !u.location).length;
    },
    totalUnits() {
      return this.selectedUnits.length;
    },
    standardOperation: {
      get() {
        return this.stdOpToAdd;
      },
      set(value) {
        this.showTable = false;
        this.stdOpToAdd = Cloner.deepClone(value);
        this.setExcedeJob(this.excedeJobOptions);
      }
    },
    confirmationMessage: function () {
      return `Are you sure you want to bulk update the ${this.selectedUnitIds.length} units selected?`;
    }
  },
  methods: {
    addStandardOperation() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error adding operation. See indicated fields below.');
        return;
      }

      this.showTable = true;
      this.$v.$reset();
    },
    setExcedeJob: function (excedeJobOptions) {
      if (excedeJobOptions.length === 1) {
        this.excedeJob = excedeJobOptions[0];
        return;
      }

      this.excedeJob = null;
    },
    standardOperationsFilter: (option, label, search) => {
      search = search.toLowerCase();
      const stdOpName = option.name.toLowerCase();
      return stdOpName.indexOf(search) > -1;
    },
    excedeJobDropdownSearchFilter: (option, label, search) => {
      search = search.toLowerCase();
      const excedeJobId = option.excedeJobId.toLowerCase();
      const description = option.description.toLowerCase();

      return excedeJobId.indexOf(search) > -1 || description.indexOf(search) > -1;
    },
    confirmSave: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error bulk updating scheduled operations. See indicated fields below.');
        return;
      }

      this.$refs.BulkUpdateWarning.show(this, this.saveStandardOperations.bind(this));
    },
    saveStandardOperations: async function () {
      const bulkUpdate = {
        unitIds: this.selectedUnitIds,
        standardOperationId: this.stdOpToAdd.standardOperationId,
        excedeJobId: this.excedeJob?.excedeJobId,
        durationDue: DataHelper.transformEmptyToNull(this.stdOpToAdd.durationDue),
        durationOverdue: DataHelper.transformEmptyToNull(this.stdOpToAdd.durationOverdue),
        mileageDue: DataHelper.transformEmptyToNull(this.stdOpToAdd.mileageDue),
        mileageOverdue: DataHelper.transformEmptyToNull(this.stdOpToAdd.mileageOverdue),
        engineHoursDue: DataHelper.transformEmptyToNull(this.stdOpToAdd.engineHoursDue),
        engineHoursOverdue: DataHelper.transformEmptyToNull(this.stdOpToAdd.engineHoursOverdue)
      };

      try {
        this.savingStandardOperation = true;
        await BulkUpdateService.postBulkUpdateStandardOperation(bulkUpdate);

        this.resetPage();

        SuccessService.createSuccessToast(this.$root, 'Scheduled Operation Updated on Bulk Units.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to bulk update scheduled operations.');
      } finally {
        this.savingStandardOperation = false;
      }
    },
    resetPage: function () {
      this.standardOperation = null;
      this.showTable = false;
      this.$v.$reset();
    },
    cancel() {
      this.$emit('close');
    },
    openOtherAside() {
      this.$emit('openOther');
    },
    getErrorMessage: getStandardOperationErrorMessage,
    hasChanges() {
      return this.standardOperation !== null;
    }
  }
};
</script>
