import axios from 'axios';
export const UNIT_PATH = '/api/units';
const STANDARD_OPERATIONS_PATH = '/api/standard-operations';
const UNIT_LOCATION_PATH = 'locations';

class BulkUpdateService {
  async postBulkUpdateStandardOperation(bulkStandardOperation) {
    const url = `${STANDARD_OPERATIONS_PATH}`;
    await axios.post(url, bulkStandardOperation);
  }

  async postBulkUpdateLocation(bulkLocation) {
    const url = `${UNIT_PATH}/${UNIT_LOCATION_PATH} `;
    await axios.put(url, bulkLocation);
  }
}

export default new BulkUpdateService(); // singleton object
